@import "variables";

.modal-container {
    display: none;

    position: fixed;
    left: 0;
    top: 0;

    z-index: 7;

    width: 100%;
    height: 100%;
    overflow: scroll;

    background-color: rgba(0, 0, 0, 0.4);
}

.modal-container.displayed {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;

    display: flex;
    flex-direction: column;
}

.modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-title h3 {
    color: $primary;
}

.modal-close {
    color: $primary;
    float: right;
    font-size: 35px;
    font-weight: bold;
}

.modal-close:hover,
.modal-close:focus {
    color: $accent;
    text-decoration: none;
    cursor: pointer;
}

@media screen and (max-width: $maxWidthMobile) {
    .modal-container {
        padding-top: 0;
    }

    .modal-content {
        width: calc(100% - 40px); // - padding x 2
        min-height: 100%;
    }
}

.nos_services__modal_image {
    align-self: center;

    background-repeat: round;
    
    width: 500px;
    max-width: 100%;
    height: 500px;
}
